import { Outlet } from "react-router-dom";
import React, {useState} from "react";
import HeroSection from "./HeroSection/HeroSection";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./FooterSection/Footer";

import AboutSection from "./AboutSection/AboutSection";
import PurchaseSection from "./PurchaseSection/PurchaseSection";

const NavbarLayout = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        console.log(isOpen)
        setIsOpen(!isOpen)
    }    
    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection/>
            <AboutSection/>
            <PurchaseSection/>

            <Footer />
        </div>
    );
};

export default NavbarLayout;