import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCuWSPCUX0csQvpL4KZtKF-YHwwaeghMUY",
  authDomain: "matinee-c72cb.firebaseapp.com",
  databaseURL: "https://matinee-c72cb-default-rtdb.firebaseio.com",
  projectId: "matinee-c72cb",
  storageBucket: "matinee-c72cb.appspot.com",
  messagingSenderId: "955162595051",
  appId: "1:955162595051:web:8965010222540b63c1b8ae"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);