import React from 'react';

function OrderConfirmation({ orderDetails }) {
    console.log(orderDetails)
  return (
    <div>
      <h2>Order Confirmation</h2>
      <p>Thank you for your order!</p>
      <h3>Order Details:</h3>
      <p><strong>Quantity:</strong> {orderDetails.quantity}</p>
      <p><strong>Email Address:</strong> {orderDetails.email}</p>
      <p><strong>Shipping Address:</strong></p>
      <p>
        {orderDetails.firstName + ' ' + orderDetails.lastName}
        <br/>
        {orderDetails.streetAddress}
        <br/>
        {orderDetails.city + ' ' + orderDetails.state + ' ' + orderDetails.zipCode}
        </p>
      <p><strong>Total Cost:</strong> ${orderDetails.totalCost}</p>
      <h2>Please Read:</h2>
      <p><strong>Payment Instructions</strong></p>
      <p>Please send the full amount by venmo to @matinee. Once your payment is received, your item will be discreetly shipped to you.</p>
      <p>A confirmation email has been sent to your address. Please check your spam folder if you don't see it in your inbox!</p>
    </div>
  );
}

export default OrderConfirmation;