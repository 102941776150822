import React, {useEffect, useState} from "react";
import {FaBars} from "react-icons/fa"
import { NavLink } from "react-router-dom";
import {animateScroll as scroll} from 'react-scroll'
import {
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    RegLink,
    NavBg,
    ImageBg
} from "./NavbarElements"
import header from "../../assets/matinee_header.png"


function Navbar({toggle}) {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 100) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(()=> {
        window.addEventListener('scroll', changeNav);
        console.log(scrollNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    console.log(scrollNav)
    return (
        <> 
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        {/* <NavBg>
                            <ImageBg src={navbanner}/>
                        </NavBg> */}
                        <NavLogo smooth spy to='/' onClick={toggleHome}>
                            {/* <img className="w-40 mt-2 -ml-4" src={header} alt="matinee"/> */}
                        </NavLogo>
                        <MobileIcon scrollNav={scrollNav} onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact={true} offset={-80}  to='about'>about</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact={true} offset={-80} to='purchase'>purchase</NavLinks>
                            </NavItem>
                            <NavItem>
                                <RegLink href="http://www.instagram.com/matineechocolate">instagram</RegLink>
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
                {/* <div 
                    class="px-5 lg:mx-32 md:mx-16 sm:mx-2 md:text-2xl py-3 flex items-center justify-between flex-row text-base italic tracking-wide"
                >
                    <NavLink to="/reservations" activeClassName="active" className="hvr-wobble-top">
                        reservations
                    </NavLink>
                    <NavLink to="/menu" activeClassName="active" className="hvr-wobble-top">
                        menu
                    </NavLink>   
                    <NavLink to="/about" activeClassName="active" className="hvr-wobble-top">
                        about
                    </NavLink>
                    <NavLink to="/gallery" activeClassName="active" className="hvr-wobble-top">
                        gallery
                    </NavLink>                   
                    <NavLink to="/signup" activeClassName="active" className="hvr-wobble-top">
                        subscribe
                    </NavLink>
                </div> */}
            

        </>
    )
    }
    
    export default Navbar;