import React, {useState} from 'react'
import { 
    HeroContainer, 
    HeroBg, 
    ImageBg,
    VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,

} from './HeroElements'
import {Button, ArrowForward, ArrowRight} from '../ButtonElement'
import video from "../../assets/video_wide.mp4"
import MatineeHeader from "../../assets/matinee_header.png"

const HeroSection = () => {
    const [hoverRes, setHoverRes] = useState(false)
    const [hoverIG, setHoverIG] = useState(false)

    const onHoverRes = () => {
        setHoverRes(!hoverRes)
    }
    const onHoverIG = () => {
        setHoverIG(!hoverIG)
    }

  return (
    <HeroContainer id="home">
        <HeroBg>
            <VideoBg height="100%" muted autoPlay playsInline loop defaultMuted>
                <source src={video} type='video/mp4'></source>
            </VideoBg>
        </HeroBg>
        <HeroContent>
            <HeroH1>
                <ImageBg src={MatineeHeader}/>
            </HeroH1>
            <HeroP>
                magic chocolate
            </HeroP>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection