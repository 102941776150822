import styled from 'styled-components'

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 30px;
    height: 900px;
    width: 100%;
    position: relative;
    z-index: 1;

    // :before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     z-index: 2;
    // }
`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`

export const VideoBg = styled.video`
    z-index: 99999;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`

export const HeroContent = styled.div`
    z-index: 1;
    margin-top: -220px;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroH1 = styled.h1`
    color: #FFDEAA;
    letter-spacing: 0px;
    text-align: center;
    line-height: 0.9em;
    width: 600px;
    margin-bottom: -20px;

    @media screen and (max-width: 768px){
        width: 320px;
    }

`

export const HeroP = styled.p`
    color: #FFDEAA;
    font-size: 30px;
    font-family: 'Poppins';
    text-align: center;
    max-width: 600px;
    line-height: 1.8em;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
