import React from 'react';
import Hyphenated from 'react-hyphen';
import {RegLink} from './PurchaseElements.js'

function About() {
  return (
    <div>
      <Hyphenated>
        <p>
          To schedule a delivery or pickup (currently serving the NYC area only), reach out via email with your WhatsApp or Signal number <RegLink href="mailto:matineemagicchocolate@gmail.com">here</RegLink> or send a DM on our instagram page.
        </p>
        <p>
          Alternatively, to place a shipped order, please enter your shipping information below.
        </p>
      </Hyphenated>
    </div>
    )
}

export default About