import styled from 'styled-components'
import {Link as LinkR } from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background: ${({scrollNav})=>(scrollNav ? "#100404" : "transparent")} ;
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
}

`
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1100px;
`

export const NavBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    opacity: 50%;
`
export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`


export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-weight: bold;
    text-decoration: none;
    font-family: "Oranienbaum"
`

export const MobileIcon = styled.div`
    display: none;
    

    @media screen  and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 80%);
        font-size: 1.8rem;
        cursor: pointer;
        color: ${({scrollNav})=>(scrollNav ? "#FFDEAA" : "#FFDEAA")} ;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px
`

export const NavLinks = styled(LinkS)`
    color: ${({scrollNav})=>(scrollNav ? "#FFDEAA" : "#FFDEAA")} ;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-family: Poppins;

    $.active {
        border-bottom: 3px solid #01bf71;
    }
`

export const RegLink = styled.a`
    color: ${({scrollNav})=>(scrollNav ? "#FFDEAA" : "#FFDEAA")} ;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-family: Poppins;

    $.active {
        border-bottom: 3px solid #01bf71;
    }
`