import React from 'react';
import Hyphenated from 'react-hyphen';

function About() {
  return (
    <div className="max-w-xl">
      <img>
      </img>
    </div>
    )
}

export default About