import React from 'react';
import {FooterContainer} from './FooterElements.js'

function Footer() {
  return (
    <FooterContainer>
      {/* Your footer content goes here */}
    </FooterContainer>
  );
}

export default Footer;
