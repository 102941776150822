import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {Link as LinkS} from 'react-scroll'


export const SidebarContainer = styled.aside`
    position: fixed;
    width: 100%;
    height: 100%;
    background: #100404;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen })=> (isOpen ? '1' : '0')};
    z-index: ${({ isOpen })=> (isOpen ? '999' : '-10')};
`

export const CloseIcon = styled(FaTimes)`
    color: #FFDEAA
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
color: #fff
`
export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 80px);
    text-align: center;
    
    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(7, 60px)
    }
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    font-family: Poppins;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-styled: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color:  #FFDEAA;
    cursor: pointer;

    &:hover {
        color: #d74e2b;
        transition: 0.2s ease-in-out;
    }
`

export const RegLink = styled.a`
    display: flex;
    font-family: Poppins;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-styled: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color:  #FFDEAA;
    cursor: pointer;

    &:hover {
        color: #d74e2b;
        transition: 0.2s ease-in-out;
    }
`
