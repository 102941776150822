import styled from 'styled-components'

export const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: #100404;
    color: #FFDEAA;
    font-family: Poppins;

`
export const AboutBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`
export const AboutWrapper = styled.div`
    margin-top: 40px;
    padding-bottom: 50px;
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
`

export const AboutRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1` : `'col1 col2'`)};

    @media screen and (max-width: 1000px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`

export const Column1 = styled.div`
    padding: 0 25px;
    grid-area: col1;
    @media screen and (min-width: 1000px) {
        margin-right: 10px;
        margin-bottom: 30px;
    }
`

export const Column2 = styled.div`
    margin-bottom: 40px;
    padding: 0 25px;
    grid-area: col2;
    @media screen and (min-width: 1000px) {
        margin-top: 74px;
        margin-left: 10px;
    }
`

export const BarsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const BarsDiv = styled.div`
    display: block;
    position: relative;
    top: 80px;

`

export const TextWrapper = styled.div`
    z-index: 10;
    color: #FFDEAA;
    text-align: left;

`

export const TopLine = styled.p`
    z-index: 10;
    color: #FFDEAA;
    font-family: Melodrama;
    font-size: 46px;
    font-weight: 700;
    padding-bottom: 20px;
`

export const Text = styled.p`
    
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
`
