import React from 'react'
import {
    AboutContainer,
    AboutBg,
    ImageBg,
    AboutWrapper,
    FormWrapper,
    TextWrapper,
    TopLine,
    BtnWrap,
    Footer,
} from './PurchaseElements'
import PurchaseText from './PurchaseText'
import OrderForm from './OrderForm'

// import PasscodeComponent from './Passcode'


const PurchaseSection = () => {
    
  return (
    <div>
        <AboutContainer id="purchase">
            <AboutWrapper>
            <TopLine>purchase</TopLine>
                <TextWrapper>
                    <PurchaseText/>
                    <OrderForm/>
                </TextWrapper>
            </AboutWrapper>
        </AboutContainer>
        <Footer/>
    </div>
  )
}

export default PurchaseSection