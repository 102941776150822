import React from 'react';
import Hyphenated from 'react-hyphen';

function About() {
  return (
    <div>
      <Hyphenated>
        <p>
          Matinée chocolate is crafted by hand with only organic and vegan ingredients. Each bar contains 3.5 grams of powder ground from dehydrated medicinal mushrooms.
        </p>
        <br/>
        <p>
          Every bar divides into ten tiles. A single tile (350mg) is designed to gently enhance mood; a few tiles will produce euphoria. 
        </p>
        <br/>
        <p>
          Matinée bars contain 78% cocoa solids.
        </p>
        <br/>
        <p>  
          Each bar costs $50. To purchase the product, please send an inquiry via email below, or send a DM on our instagram channel. 
        </p>
        <br/>
        <p>
          In your message, simply provide a phone number that is connected to an account on WhatsApp or Signal. 
        </p>
        <br/>
      </Hyphenated>
    </div>
    )
}

export default About