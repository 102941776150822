import React, { useState } from 'react';
import OrderConfirmation from './OrderConfirmation';
import {firestore} from '../../firebase';
import {addDoc, collection} from '@firebase/firestore'
import {StylishInput, StylishSelect, StylishButton, OrderContainer, TextWrapper} from './PurchaseElements'

function OrderForm() {

  const ref = collection(firestore, "orders")

  const [quantity, setQuantity] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [subtotal, setSubtotal] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission

  const statesList = [
    { name: 'Alabama', code: 'AL' },
    { name: 'Alaska', code: 'AK' },
    { name: 'Arizona', code: 'AZ' },
    { name: 'Arkansas', code: 'AR' },
    { name: 'California', code: 'CA' },
    { name: 'Colorado', code: 'CO' },
    { name: 'Connecticut', code: 'CT' },
    { name: 'Delaware', code: 'DE' },
    { name: 'Florida', code: 'FL' },
    { name: 'Georgia', code: 'GA' },
    { name: 'Hawaii', code: 'HI' },
    { name: 'Idaho', code: 'ID' },
    { name: 'Illinois', code: 'IL' },
    { name: 'Indiana', code: 'IN' },
    { name: 'Iowa', code: 'IA' },
    { name: 'Kansas', code: 'KS' },
    { name: 'Kentucky', code: 'KY' },
    { name: 'Louisiana', code: 'LA' },
    { name: 'Maine', code: 'ME' },
    { name: 'Maryland', code: 'MD' },
    { name: 'Massachusetts', code: 'MA' },
    { name: 'Michigan', code: 'MI' },
    { name: 'Minnesota', code: 'MN' },
    { name: 'Mississippi', code: 'MS' },
    { name: 'Missouri', code: 'MO' },
    { name: 'Montana', code: 'MT' },
    { name: 'Nebraska', code: 'NE' },
    { name: 'Nevada', code: 'NV' },
    { name: 'New Hampshire', code: 'NH' },
    { name: 'New Jersey', code: 'NJ' },
    { name: 'New Mexico', code: 'NM' },
    { name: 'New York', code: 'NY' },
    { name: 'North Carolina', code: 'NC' },
    { name: 'North Dakota', code: 'ND' },
    { name: 'Ohio', code: 'OH' },
    { name: 'Oklahoma', code: 'OK' },
    { name: 'Oregon', code: 'OR' },
    { name: 'Pennsylvania', code: 'PA' },
    { name: 'Rhode Island', code: 'RI' },
    { name: 'South Carolina', code: 'SC' },
    { name: 'South Dakota', code: 'SD' },
    { name: 'Tennessee', code: 'TN' },
    { name: 'Texas', code: 'TX' },
    { name: 'Utah', code: 'UT' },
    { name: 'Vermont', code: 'VT' },
    { name: 'Virginia', code: 'VA' },
    { name: 'Washington', code: 'WA' },
    { name: 'West Virginia', code: 'WV' },
    { name: 'Wisconsin', code: 'WI' },
    { name: 'Wyoming', code: 'WY' }
  ];
  

  const handleQuantityChange = (event) => {
    const quantityValue = event.target.value;
    setQuantity(quantityValue);
    if (!isNaN(quantityValue)) {
      const subtotalValue = parseInt(quantityValue) * 50;
      const shippingCost = 10;
      const totalCostValue = subtotalValue + shippingCost;
      setSubtotal(subtotalValue);
      setTotalCost(totalCostValue);
    }
  };

  function sendEmail() {
    // Data to be sent in the POST request
    const data = {
      email_address: email,
      subject: "Thanks for your Matinée order.",
      html: 
      `<p>Thank you for your order!</p><p><strong>Quantity:</strong>
      ${quantity}</p>
      <p><strong>Email Address: </strong>${email}</p>
      <p><strong>Shipping Address:</strong></p>
      <p>
        ${firstName + ' ' + lastName}
        <br/>
        ${streetAddress}
        <br/>
        ${city + ' ' + state + ' ' + zipCode}
        </p>
      <p><strong>Total Cost:</strong> ${totalCost}</p>
      <p>Please send the full amount by venmo to @matinee. Once your payment is received, your item will be discreetly shipped to you.</p>`,
    };
  
    // URL of your Cloud Function endpoint
    const url = 'https://us-central1-matinee-c72cb.cloudfunctions.net/sendEmail';
  
    // Options for the fetch request
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
  
    // Make POST request
    fetch(url, options)

      .then(response => {
        if (response.ok) {
          console.log('Email sent successfully');
        } else {
          console.error('Failed to send email');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (parseInt(quantity) <= 0 || isNaN(parseInt(quantity))) {
        alert('Quantity must be greater than 0.');
        return;
      }

    setIsSubmitted(true);
    sendEmail();

    let data = {
      quantity: quantity,
      email: email,
      first_name: firstName,
      last_name: lastName,
      street_address: streetAddress,
      city: city,
      state: state,
      zip: zipCode,
      cost: totalCost
    };

    try {
      addDoc(ref, data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
    {!isSubmitted ? (
      <div>
        <div>
            <OrderContainer>
            <h2>Order Matinée Online</h2>
              
            <TextWrapper>
                Please fill out the information below and proceed to the payment. Once your payment is received we will send you the product in discreet packaging.
            </TextWrapper>
                <br/>
            <div>
            <form onSubmit={handleSubmit}>
            <div>
            <label htmlFor="quantity">Number of Bars:</label>
            <StylishInput
                type="number"
                id="quantity"
                value={quantity}
                onChange={handleQuantityChange}
                required
            />
            </div>
            <div>
            <label htmlFor="subtotal">Subtotal:</label>
            <StylishInput
                type="text"
                id="subtotal"
                value={`$${subtotal}`}
                readOnly
            />
            </div>
            <div>
            <label htmlFor="shipping">Shipping Cost:</label>
            <StylishInput
                type="text"
                id="shipping"
                value="$10"
                readOnly
            />
            </div>
            <div>
            <label htmlFor="totalCost">Total Cost:</label>
            <StylishInput
                type="text"
                id="totalCost"
                value={`$${totalCost}`}
                readOnly
            />
            </div>
            <h2>Shipping Information</h2>

            <div>
            <label htmlFor="email">Email Address:</label>
            <StylishInput
                type="text"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
            />
            </div>
            <div>
            <label htmlFor="firstName">First Name:</label>
            <StylishInput
                type="text"
                id="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
            />
            </div>
            <div>
            <label htmlFor="lastName">Last Name:</label>
            <StylishInput
                type="text"
                id="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                required
            />
            </div>
            <div>
            <label htmlFor="streetAddress">Street Address:</label>
            <StylishInput
                type="text"
                id="streetAddress"
                value={streetAddress}
                onChange={(event) => setStreetAddress(event.target.value)}
                required
            />
            </div>
            <div>
            <label htmlFor="city">City:</label>
            <StylishInput
                type="text"
                id="city"
                value={city}
                onChange={(event) => setCity(event.target.value)}
                required
            />
            </div>
            <div>
            <label htmlFor="state">State:</label>
            <StylishSelect
                id="state"
                value={state}
                onChange={(event) => setState(event.target.value)}
                required
            >
                <option value="">Select State</option>
                {statesList.map((stateObj, index) => (
                <option key={index} value={stateObj.code}>
                    {stateObj.name}
                </option>
                ))}
            </StylishSelect>
            </div>
            <div>
            <label htmlFor="zipCode">Zip Code:</label>
            <StylishInput
                type="text"
                id="zipCode"
                value={zipCode}
                onChange={(event) => setZipCode(event.target.value)}
                required
            />
            </div>
            <StylishButton type="submit">Submit Order</StylishButton>
            </form>

            </div>
            </OrderContainer>
          </div>
      </div>
    ) : (
      <OrderConfirmation
        orderDetails={{
          quantity,
          firstName,
          lastName,
          streetAddress,
          city,
          state,
          zipCode,
          totalCost,
          email
        }}
      />
    )}
  </div>
    
  );
}

export default OrderForm;
