import styled from 'styled-components'

export const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: #100404;
    color: #FFDEAA;
    font-family: Poppins;

`
export const AboutBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`
export const AboutWrapper = styled.div`
    margin-top: 40px;
    padding-bottom: 50px;
    padding: 0 25px;
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    justify-content: center;
`

export const FormWrapper = styled.div`
    margin-top: 40px;
    padding-bottom: 50px;
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    justify-content: center;
`

export const TextWrapper = styled.div`
    z-index: 10;
    color: #FFDEAA;
    text-align: left;
    max-width: 600px;
`

export const AboutRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1` : `'col1 col2'`)};

    @media screen and (max-width: 1000px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`

export const Column1 = styled.div`
    grid-area: col1;
    @media screen and (min-width: 1000px) {
        margin-right: 10px;
        margin-bottom: 30px;
    }
`

export const Column2 = styled.div`
    margin-bottom: 40px;
    padding: 0 15px;
    grid-area: col2;
    @media screen and (min-width: 1000px) {
        margin-top: 74px;
        margin-left: 10px;
    }
`


export const TopLine = styled.p`
    z-index: 10;
    color: #FFDEAA;
    font-family: Melodrama;
    font-size: 46px;
    font-weight: 700;
    padding-bottom: 20px;
`

export const Text = styled.p`
    
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
`

export const RegLink = styled.a`
    text-decoration: none;
    list-styled: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color:  #d74e2b;
    cursor: pointer;

    &:hover {
        color: #FFDEAA;
        transition: 0.2s ease-in-out;
    }
`

export const Footer = styled.div`
    display: flex;
    width: 100%;
    height: 150px;
    z-index: 1;
    background-color: #100404;
    color: #FFDEAA;
`

export const StylishInput = styled.input`
    font-family: Poppins;
    background-color: #100404;
    color: #FFDEAA;
    margin: 5px;
    min-width: 150px;
    max-width: 400px;
    width: auto;
    border: 1px solid #FFDEAA;
    border-radius: 5px;
    font-size: 13px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
`
export const StylishCodeInput = styled.input`
    font-family: Poppins;
    background-color: #100404;
    color: #FFDEAA;
    min-width: 150px;
    max-width: 400px;
    border: 1px solid #FFDEAA;
    border-radius: 5px;
    font-size: 13px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 20px;
    margin-bottom: 20px;
`

export const StylishButton = styled.button`
    font-family: Poppins;
    background-color: #FFDEAA;
    color: #100404;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #FFDEAA;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
`

export const StylishContainer = styled.div`
    
}
`
export const OrderContainer = styled.div`

}
`

export const StylishSelect = styled.select`
    font-family: Poppins;
    background-color: #100404;
    color: #FFDEAA;
    margin: 5px;
    min-width: 20%;
    border: 1px solid #FFDEAA;
    border-radius: 5px;
    font-size: 13px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
`