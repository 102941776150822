import React from 'react'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarLink, 
    SidebarWrapper,
    SidebarMenu,
    RegLink
} from './SidebarElements'

const Sidebar = ({toggle, isOpen}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>

                {/* <SidebarLink onClick={toggle} offset={-80} to="gallery">
                    gallery
                </SidebarLink> */}
                <SidebarLink onClick={toggle} offset={-80} to="about">
                    about
                </SidebarLink>
                <SidebarLink onClick={toggle} offset={-80} to="purchase">
                    purchase
                </SidebarLink>
                <RegLink href="http://www.instagram.com/matineechocolate">
                    instagram
                </RegLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar