import React from 'react'
import {
    AboutContainer,
    AboutBg,
    ImageBg,
    AboutWrapper,
    AboutRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    BarsWrapper,
    BarsDiv,
    BtnWrap,
} from './AboutElements'
import About from './AboutText'
import About2 from './AboutText2'
import bar_front from '../../assets/bar_front.png'
import bar_back from '../../assets/bar_back.png'
import bars from '../../assets/bars.png'

const AboutSection = () => {
  return (
    <div>
        <AboutContainer id="about">
            <AboutWrapper>
                <AboutRow>
                    <Column1>
                        <TopLine>about</TopLine>
                        <TextWrapper>
                            <About/>
                        </TextWrapper>

                    </Column1>
                    <Column2>
                        <BarsWrapper>
                            <BarsDiv> 
                                <img src={bars} alt="" width="360px"/>
                            </BarsDiv>
                            {/* <div>
                                <img src={bar_front} alt="" width="150px" />
                            </div>    */}
                        </BarsWrapper>
                    </Column2>
                </AboutRow>
            </AboutWrapper>
        </AboutContainer>
    </div>
  )
}

export default AboutSection